import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { NewNav } from "./components/navbar/NewNav.jsx";
import { NavbarComponent } from "./components/navbar/Navbar.jsx";
import { Footer } from "./components/footer/Footer.jsx";
import { store, persistor } from "./store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <NewNav />
        <App />
        <Footer />
      </PersistGate>
    </Provider>
  </React.StrictMode>
);
