import React from 'react';
import styles from './mysqlExamDetailsPage.module.css';

const MysqlExamDetailsPage = () => {
    return (
        <div className={styles.examContainer}>
            <h1>MySQL Backend Developer Exam Outline</h1>
            <section>
                <h2>About the MySQL Backend Developer Exam</h2>
                <p>
                    The MySQL Backend Developer Exam is tailored for individuals who aim to validate their skills in working with MySQL, one of the most popular relational database management systems. This exam is ideal for those looking to showcase their capability in database management and development, particularly in environments that rely on MySQL for data storage and retrieval. Achieving certification in MySQL Proficiency assures employers of your adeptness in managing databases effectively, making you a valuable asset in roles such as database administration, backend development, and data analysis.
                </p>
            </section>
            <section>
                <h2>Topics Covered in the MySQL Proficiency Exam</h2>
                <ul className="no-bullets">
                    <li><strong>Downloading and Installing MySQL Interface Applications</strong>
                        <ul>
                            <li>Steps for downloading and installing a MySQL interface application (e.g., MySQL Workbench)</li>
                            <li>Configuring the interface application for optimal use</li>
                        </ul>
                    </li>
                    <li><strong>Creating and Managing MySQL Schemas</strong>
                        <ul>
                            <li>Understanding the structure and components of a MySQL schema</li>
                            <li>Procedures for creating, modifying, and deleting schemas</li>
                            <li>Best practices for schema design and management</li>
                        </ul>
                    </li>
                    <li><strong>Database Connections</strong>
                        <ul>
                            <li>Techniques for connecting to a MySQL database through the interface application</li>
                            <li>Troubleshooting common connection issues</li>
                            <li>Managing multiple database connections within the interface</li>
                        </ul>
                    </li>
                    <li><strong>Database Management</strong>
                        <ul>
                            <li>Performing essential database operations such as creating, reading, updating, and deleting (CRUD) data</li>
                            <li>Utilizing the interface application to manage database users and permissions</li>
                            <li>Implementing backup and restore procedures for database safety</li>
                        </ul>
                    </li>
                    <li><strong>Using MySQL Interface Applications</strong>
                        <ul>
                            <li>Navigating and utilizing key features of MySQL Workbench or other interface applications</li>
                            <li>Running SQL queries and scripts efficiently</li>
                            <li>Visualizing and analyzing database structures and data through the interface tools</li>
                        </ul>
                    </li>
                </ul>
            </section>
            <section>
                <h2>MySQL Backend Developer Examination Information</h2>
                <table>
                    <tbody>
                        <tr>
                            <td>Length of exam</td>
                            <td>60 minutes</td>
                        </tr>
                        <tr>
                            <td>Number of items</td>
                            <td>90</td>
                        </tr>
                        <tr>
                            <td>Item format</td>
                            <td>Multiple choice</td>
                        </tr>
                        <tr>
                            <td>Passing Score</td>
                            <td>70%</td>
                        </tr>
                        <tr>
                            <td>Exam language availability</td>
                            <td>English</td>
                        </tr>
                        <tr>
                            <td>Testing options</td>
                            <td>Online Testing</td>
                        </tr>
                    </tbody>
                </table>
            </section>
            <section>
                <h2>MySQL Backend Developer Examination Weights</h2>
                <table>
                    <thead>
                        <tr>
                            <th>Domain</th>
                            <th>Task</th>
                            <th>Recommended % of test by Domain</th>
                            <th>Recommended # of questions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>MySQL Fundamentals</td>
                            <td>
                                <ul>
                                    <li>Download and install MySQL interface application (e.g., Workbench)</li>
                                    <li>Create a MySQL schema</li>
                                    <li>Connect to the new database through interface application</li>
                                    <li>Manage MySQL database through interface application</li>
                                </ul>
                            </td>
                            <td>16.47%</td>
                            <td>21</td>
                        </tr>
                        <tr>
                            <td>Data Types and Constraints</td>
                            <td>
                                <ul>
                                    <li>Identify data type constraints and their usage</li>
                                    <li>Design tables with common data types (e.g., INT, VARCHAR, DATE) and constraints</li>
                                </ul>
                            </td>
                            <td>8.68%</td>
                            <td>11</td>
                        </tr>
                        <tr>
                            <td>Data Manipulation</td>
                            <td>
                                <ul>
                                    <li>Execute SQL commands to create tables</li>
                                    <li>Update a table with a new column</li>
                                    <li>Remove a column from an existing table</li>
                                    <li>Select data from a table to retrieve specific columns and rows based on certain conditions</li>
                                    <li>Insert new records into a table, ensuring the data meets the table's constraints and requirements</li>
                                    <li>Update existing records in a table to modify one or more column values based on specific conditions</li>
                                    <li>Delete records from a table that meet certain conditions to remove outdated or incorrect data</li>
                                    <li>Join multiple tables to combine data from different sources based on related columns</li>
                                </ul>
                            </td>
                            <td>7.85%</td>
                            <td>10</td>
                        </tr>
                        <tr>
                            <td>Database Design</td>
                            <td>
                                <ul>
                                    <li>Implement database design concepts (e.g., ER diagrams, normalization forms)</li>
                                    <li>Utilize primary keys, foreign keys, and relationships</li>
                                    <li>Design a sample database schema using normalization principles</li>
                                    <li>Implement normalization techniques to reduce data redundancy</li>
                                </ul>
                            </td>
                            <td>5.52%</td>
                            <td>7</td>
                        </tr>
                        <tr>
                            <td>MySQL Security</td>
                            <td>
                                <ul>
                                    <li>Implement MySQL security mechanisms (authentication, authorization, encryption)</li>
                                    <li>Implement SQL injection prevention techniques</li>
                                </ul>
                            </td>
                            <td>5.51%</td>
                            <td>7</td>
                        </tr>
                        <tr>
                            <td>User Management</td>
                            <td>
                                <ul>
                                    <li>Create new MySQL users with specific privileges</li>
                                    <li>Manage user roles and permissions</li>
                                </ul>
                            </td>
                            <td>4.73%</td>
                            <td>6</td>
                        </tr>
                        <tr>
                            <td>Backup and Recovery</td>
                            <td>
                                <ul>
                                    <li>Back up MySQL databases using MySQL interface application</li>
                                    <li>Apply backup strategies (full backup, incremental backup)</li>
                                    <li>Schedule automated backups using MySQL events</li>
                                    <li>Implement MySQL recovery techniques (e.g., point-in-time recovery, backup restoration)</li>
                                    <li>Restore databases from backups</li>
                                </ul>
                            </td>
                            <td>3.92%</td>
                            <td>5</td>
                        </tr>
                        <tr>
                            <td>Stored Procedures and Functions</td>
                            <td>
                                <ul>
                                    <li>Create a stored procedure that accepts parameters and performs a series of SQL statements</li>
                                    <li>Design a function that takes one or more inputs and returns a calculated value based on a specific algorithm or business logic</li>
                                    <li>Debug a stored procedure or function to identify and fix errors in the SQL code or logic, ensuring correct execution</li>
                                </ul>
                            </td>
                            <td>6.31%</td>
                            <td>8</td>
                        </tr>
                        <tr>
                            <td>Triggers and Events</td>
                            <td>
                                <ul>
                                    <li>Create triggers for specified actions (e.g., INSERT, UPDATE)</li>
                                    <li>Schedule events for automated database tasks</li>
                                </ul>
                            </td>
                            <td>3.14%</td>
                            <td>4</td>
                        </tr>
                        <tr>
                            <td>Performance Tuning and Scaling</td>
                            <td>
                                <ul>
                                    <li>Monitor database performance using MySQL interface application tools</li>
                                    <li>Improve query optimization for performance</li>
                                    <li>Implement indexes</li>
                                    <li>Optimize queries for MySQL server</li>
                                </ul>
                            </td>
                            <td>5.54%</td>
                            <td>7</td>
                        </tr>
                        <tr>
                            <td>Advanced SQL Techniques</td>
                            <td>
                                <ul>
                                    <li>Create and manage views to simplify complex queries</li>
                                    <li>Implement subqueries and correlated subqueries for data retrieval</li>
                                </ul>
                            </td>
                            <td>3.13%</td>
                            <td>4</td>
                        </tr>
                    </tbody>
                </table>
            </section>
        </div>
    );
}

export default MysqlExamDetailsPage;
