import * as React from "react";
import styles from "./blogPage.module.css";
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
export default function WOIABlog() {
  return (
    <div className={styles.parent}>
      <div className={styles.header}>
        <div className={styles.headerInfo}>
          <h4>Blog </h4>
          <hr
            style={{
              height: "3px",
              width: "20px",
              margin: "0",
              border: "none",
              backgroundColor: "gray",
            }}
          />
          <p>Posted: 01 AUG 2024</p>
        </div>
        <h2 className={styles.title}>
          Boost Your IT Skills: How to Secure WIOA Funds for Certification
        </h2>
      </div>
      <div className={styles.contentMain}>
        <div className={styles.sideBar}>
          <div className={styles.socialIcons}>
            <FaFacebookF style={{cursor: 'pointer'}} onClick={() => {
              window.open("https://www.facebook.com/profile.php?id=61558199176925", "_blank");
            }}/>
            <FaInstagram style={{cursor: 'pointer'}} onClick={() => {
              window.open("https://www.instagram.com/coitb.technology/", "_blank");
            }} />
          </div>
        </div>
        <div className={styles.mainContent}>
          {/* <h4 className={styles.title}>
            {" "}
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto,
            modi.
          </h4> */}
          <p>
            Are you a job seeker struggling to find a new IT employment
            opportunity? Or have you decided to pursue a career in IT but do not
            have the funds to enroll in a certification program? The Workforce
            Innovation and Opportunity Act (WIOA) is the gateway to a world of
            opportunities in the IT industry.
          </p>
          <h4 className={styles.title}>What is WIOA?</h4>
          <p>
            Passed in July 2014, the Workforce Innovation and Opportunity Act
            (WIOA) is a U.S. law focused on strengthening the public workforce
            system in the United States by promoting job skills training and
            career advancement. It achieves its goals by improving the
            coordination of federal workforce development programs with other
            related programs, fostering a collaborative environment. WIOA-funded
            programs help job seekers find employment, education, job training,
            and financial and support services to improve their chances of
            gaining desired employment. They also play a crucial role in
            connecting employers with the skilled workers they need. A
            significant portion of WIOA’s funding is provided directly to
            states, with the mandate to use these funds to support workforce
            investment, vocational rehabilitation, employment, training,
            literacy programs, and more.
          </p>
          <h4 className={styles.title}>How does WIOA Work?</h4>
          <p>
            WIOA is decentralized and gives individual states significant
            authority to implement WIOA programs through grant funding. It holds
            states accountable and requires them to be transparent in publicly
            providing information on program outcomes. The law enhances access
            to services in the American Job Center (AJC) system, a resource for
            employers and job seekers to obtain career counseling, employment
            services, job training, and position listings.
          </p>
          <h4 className={styles.title}>How does WOIA Support Job Seekers Seeking IT Certifications:</h4>
          <p>WOIA supports individuals seeking to obtain IT certifications in many ways, including:</p>
          <ul>
            <li>Providing funding for IT training and certification programs. All classroom training is funded through Individual Training Accounts (ITAs). Training programs must be on a state’s eligible training provider list to qualify for funding.</li>
            <li>Enabling individuals to enroll in IT certification programs at community colleges, vocational schools, and other training providers.</li>
            <li>Seeking career counseling at American Job Centers.</li>
            <li>Obtaining personalized counseling on choosing the IT certifications that best match their skills, interests, and job market opportunities.</li>
            <li>Providing financial assistance for costs associated with IT certification exams, study materials, and training from recognized organizations.</li>
            <li>Support with practical work-based learning, including internships, apprenticeships, and on-the-job learning while they work towards certification.</li>
            <li>Identifying employers needing skilled workers.</li>
          </ul>
          <p>For young people, WIOA supports youth programs where job seekers can participate in IT-focused programs that lead to certifications and a path to successful careers.</p>
          <h4 className={styles.title}>Who is eligible to work with WIOA?</h4>
          <p>WIOA benefits are designed to be inclusive and available to all job seekers, including dislocated workers, young people, newly and currently employed workers, veterans, older workers, and workers with disabilities. It also supports employers seeking to hire and retain skilled workers.</p>
          <br />
          <p>Given that individual states offer WIOA-supported programs, eligibility requirements may vary; however, most have essential eligibility requirements that include:</p>
          <ul>
            <li>Be a job seeker facing significant barriers to employment.</li>
            <li>Be a low-income individual seeking career training, tuition assistance, vocational guidance, books, educational supplies, and more.</li>
            <li>Being at least 18 years old, however, some states offer youth programs that assist those between the ages of 14 and 24.</li>
            <li>Be a citizen of the United States and be eligible to work in the U.S.</li>
            <li>Show proof of a social security number.</li>
            <li>Males born after January 1, 1960, must be registered with the Selective Service.</li>
            <li>Being unemployed, underemployed, or have lost their jobs through no fault of their own.</li>
            <li>Veterans, individuals with disabilities, displaced homeowners, and ex-offenders may be eligible.</li>
          </ul>
          <p>WIOA benefits vary by state and workforce development boards, so applicants need to work with their local American Job Center to determine their eligibility.</p>
          <h4 className={styles.title}>To take advantage of the benefits WIOA has to offer, a job seeker must do the following:</h4>
          <ol>
            <li>Visit an American Job Center in their state. The U.S. Department of Labor’s website lists locations.</li>
            <li>Meet with a WIOA counselor at an American Job Center to determine eligibility. In this meeting, the counselor will discuss employment goals, skills, and any barriers to moving forward.</li>
            <li>Provide documentation, such as identification, proof of income, and employment history. These documents will determine eligibility and the services you qualify for.
              <ul>
                <li>Documents may be submitted online or in person. This is dependent on the individual American Job Center.</li>
              </ul>
            </li>
          </ol>
          <p>If you are an unemployed or under-employed job seeker or employer needing skilled IT staff, WIOA-based programs may provide the support and resources needed for success.</p>
          <div className={styles.references}>
            <h4 className={styles.title}>References:</h4>
            <ul>
              <li>
                <a href="https://www.americanprogress.org/article/recommendations-for-reauthorizing-the-workforce-innovation-and-opportunity-act/" target="_blank" rel="noopener noreferrer">
                  Recommendations for Reauthorizing the Workforce Innovation and Opportunity Act
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
