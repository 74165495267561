import styles from "./boardPage.modules.css";
import React, { useState } from "react";
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa6';

// Bio component to handle "read more" functionality
const Bio = ({ shortText, fullText }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <p className="boardBioText">
      {isExpanded ? fullText : shortText}
      <br />
      <br />
      <span onClick={handleToggle} className="readMoreToggle">
        {isExpanded ? 'Read less...' : 'Read more...'}
      </span>
    </p>
  );
};

// Board bios
const boardBio = (id) => {
  switch (id) {
    case 0:
      return (
        <Bio
          shortText="A seasoned technology professional with extensive experience in software development and IT management, Danny Ken brings a unique blend of leadership and technical expertise to the table..."
          fullText="A seasoned technology professional with extensive experience in software development and IT management, Danny Ken brings a unique blend of leadership and technical expertise to the table, complemented by his military background. Having worked as a director in software development, Danny has played a crucial role in driving technological advancements and aligning them with strategic business objectives.

          His educational background includes earning an Associate of Arts (AA) degree and a Bachelor of Applied Science (BAS) degree in Software Development at Valencia College, which has further strengthened his foundation in the field.

          One of his notable achievements is spearheading the growth of software development departments, leveraging his experience in leading teams from inception to expansion. With a proven track record of building high-performing teams, Danny has successfully grown departments to include up to 37 developers.

          His leadership extends beyond software development, having managed various IT initiatives and programs that contribute significantly to organizational success. In particular, Danny has been instrumental in designing and implementing solutions tailored for small businesses, leveraging his understanding of the unique challenges they face.

          In addition to his civilian career, Danny's military experience underscores his leadership abilities and commitment to excellence. His time in the military honed his strategic mindset, teamwork skills, and dedication to mission success, qualities that continue to drive his achievements in the technology sector.

          Committed to continuous learning and professional growth, he holds strategic and operational level certificates, demonstrating his dedication to excellence in leadership and operational effectiveness. Danny's passion for technology, combined with his military background and hands-on experience, enables him to drive innovation, foster collaboration, and achieve business excellence."
        />
      );
    case 1:
      return (
        <Bio
          shortText="Founder and CEO of Close the Gap Consulting, LLC (CTG) in 2015 which is a small veteran owned company, serving transitioning service members..."
          fullText="Founder and CEO of Close the Gap Consulting, LLC (CTG) in 2015 which is a small veteran owned company, serving transitioning service members, Reserve, National Guard, spouses, veterans in the areas of Training, Operations, Logistics, Human Resources, and Information Technology.

          We are agile, flexible, responsive, and perhaps most important, unburdened by layers of corporate policy constraints. The fact that we’ve continued our steady growth is a testament to our diversity, responsiveness, and forward-looking management philosophy.

          CTG is set apart by our dedication to quality, timeliness, and pragmatism."
        />
      );
    case 2:
      return (
        <Bio
          shortText="Bringing over two decades of invaluable experience as a dedicated Army veteran, Hector Guerra has served our country with honor and distinction..."
          fullText="Bringing over two decades of invaluable experience as a dedicated Army veteran, Hector Guerra has served our country with honor and distinction. With a background spanning the Department of Labor Apprenticeship, business-to-business consultancy, project management, marketing, and curriculum development, Hector is well-equipped with a diverse skill set to tackle complex challenges.

          Proficient in a range of tools including Client Relationship Management (CRM) platforms such as Monday.com, Salesforce, and Zoho, Hector excels in leveraging technology to streamline operations and enhance client satisfaction. Additionally, his expertise extends to Learning Management Systems (LMS), including TKS360 LMS, iSpring Learn, and Canvas, empowering organizations to optimize their training and educational initiatives.

          As the Founder and CEO of Era Solutions, Hector has demonstrated exemplary leadership and management capabilities, driving business growth and fostering innovation. His experience in scaling businesses and serving as subject matter expert (SME) in education support services, particularly through WIOA workforce solutions and DOL Apprenticeship programs, underscores his commitment to empowering individuals and organizations alike.

          A key focus of Hector's work has been bridging military professionals into rewarding Information Technology careers post-military service. His dedication to this cause has made a tangible impact on countless lives, facilitating transitions and ensuring success in the civilian workforce.

          Hector Guerra is a visionary leader with a proven track record of delivering results and making a positive difference in the lives of others. His blend of military discipline, business acumen, and passion for education makes him a valuable asset to any organization or initiative aiming for success and impact."
        />
      );
    case 3:
      return <p className="boardBioText"><br /><br /><br /></p>;
    case 4:
      return (
        <Bio
          shortText="With over ten years of experience in curriculum development, enhanced by a deep commitment to supporting veterans through a nonprofit organization..."
          fullText="With over ten years of experience in curriculum development, enhanced by a deep commitment to supporting veterans through a nonprofit organization, Michael MacDonald's journey in education has been driven by a passion for creating accessible and innovative educational materials. Expertise lies in developing curriculums that address diverse learning needs, making education more equitable and inclusive.

          Throughout a career spanning various educational roles, from teaching to administrative positions, Michael's methodologies emphasize adaptive learning strategies and the integration of real-life applications, ensuring that learners are equipped with both theoretical knowledge and practical skills. His contributions have positively impacted educational communities, fostering environments where learning is both engaging and effective.

          In founding the nonprofit organization, attention turned to the unique challenges veterans face when transitioning back to civilian life. The organization offers a spectrum of services, including career counseling and mental health support, reflecting Michael MacDonald's dedication to enhancing the post-service lives of veterans. Under his leadership, the organization has become a vital resource that supports veterans' reintegration into society.

          An active participant in educational and veteran affairs conferences, Michael MacDonald continues to influence discussions on how to improve educational practices and veteran support systems. His work in curriculum development and veteran support underscores a career committed to service and educational excellence, making significant strides in both fields."
        />
      );
    default:
      return null;
  }
};

// Board object array
const boardObj = [
  {
    id: 0,
    src: "/images/board-images/DKT.png",
    src2: "/images/board-images/DK.png",
    alt: "board1.jpg",
    cardHeader: "Danny Ken",
    cardInfo: "Secretary",
  },
  {
    id: 1,
    src: "/images/board-images/KCT.png",
    src2: "/images/board-images/KC.png",
    alt: "board2.jpg",
    cardHeader: "Kyle Crump",
    cardInfo: "Vice Chairman",
  },
  {
    id: 2,
    src: "/images/board-images/HGT.png",
    src2: "/images/board-images/HG.png",
    alt: "board3.jpg",
    cardHeader: "Hector Guerra",
    cardInfo: "Chairman and President",
  },
  {
    id: 3,
    src: "/images/board-images/unisexProfilePic.jpeg",
    src2: "/images/board-images/unisexProfilePic.jpeg",
    alt: "board2.jpg",
    cardHeader: "Brandon Martin",
    cardInfo: "Treasurer",
  },
  // {
  //   id: 4,
  //   src: "/images/board-images/MDT.png",
  //   src2: "/images/board-images/MD.png",
  //   alt: "board3.jpg",
  //   cardHeader: "Michael MacDonald",
  //   cardInfo: "Project Manager",
  // },
];

export default function Board() {
  let [current, setCurrent] = useState(0);

  function handleCurrent(num) {
    setCurrent(current + num);
    if (current === -1) setCurrent(4);
    else if (current > boardObj.length - 1) setCurrent(0);
  }

  return (
    <section className="body">
      <section className={styles.carousel}>
        <button onClick={() => handleCurrent(-1)} className="carouselButtonLeft">
          <FaAngleLeft size={'3.8rem'} color={'black'} />
        </button>
        <button onClick={() => handleCurrent(1)} className="carouselButtonRight">
          <FaAngleRight size={'3.8rem'} color={'black'} />
        </button>

        {boardObj.map((item) =>
          item.id === current ? (
            <section className="carouselContent" key={item.id}>
              <section className="boardInfo">
                <h1 className="HeaderAnimation">{item.cardHeader}</h1>
                <h3 className="InfoAnimation">{item.cardInfo}</h3>
                {boardBio(item.id)}
              </section>
              <img className="ImgAnimation" src={item.src} alt="head shot"/>
            </section>
          ) : (
            ""
          )
        )}
        <section className="bottomMenu">
          {boardObj.map((item) =>
            item.id === current ? (
              <section className="bottomMenuContent AnimateProfile" key={item.id}>
                <img src={item.src2} alt="Bio headshot"></img>
                <h1>{item.cardHeader}</h1>
                <h3>{item.cardInfo}</h3>
              </section>
            ) : (
              <section className="bottomMenuContent" key={item.id}>
                <img src={item.src2}></img>
                <h1>{item.cardHeader}</h1>
                <h3>{item.cardInfo}</h3>
              </section>
            )
          )}
        </section>
      </section>
    </section>
  );
}
