import "./homePage.css";
import "./responsive.css";
import { HashLink as Link } from "react-router-hash-link";
import { TypeAnimation } from 'react-type-animation';

const imageAssets = {
  landingCover: "/images/home-images/LandingHero.png",

  lightbulbLaptop: "/images/home-images/lightbulbLaptop.png",
};

const credentialCardObj = [
  {
    id: 0,
    title: "software-developer",
    src: "/images/home-images/jsCover.png",
    alt: "JavaScript Cover",
    cardHeader: "Software Developer JS",
    cardInfo:
      "This certification attests to the holder's ability to design and implement robust solutions using JavaScript, a fundamental skill for software development.",
  },
  {
    id: 1,
    title: "backend-developer",
    src: "/images/home-images/sqlcover.png",
    alt: "SQL Cover",
    cardHeader: "Backend Developer MySQL",
    cardInfo:
      "Holders of this certification are adept at leveraging MySQL to ensure efficient data storage, retrieval, and manipulation in backend development.",
  },
  {
    id: 2,
    title: "technical-sales",
    src: "/images/home-images/tsscourse.png",
    alt: "Technical Sales Specialist Cover",
    cardHeader: "Technical Sales Specialist",
    cardInfo:
      "This certification showcases a strong grasp of technical product/service details and the skill to communicate their value effectively in sales.",
  },
];

const boardObj = [
  {
    id: 0,
    src: "/images/board-images/DKT.png",
    src2: "/images/board-images/DK.png",
    alt: "board1.jpg",
    cardHeader: "Danny Ken",
    cardInfo: "Secretary",
  },
  {
    id: 1,
    src: "/images/board-images/KCT.png",
    src2: "/images/board-images/KC.png",
    alt: "board2.jpg",
    cardHeader: "Kyle Crump",
    cardInfo: "Vice Chairman",
  },
  {
    id: 2,
    src: "/images/board-images/HGT.png",
    src2: "/images/board-images/HG.png",
    alt: "board3.jpg",
    cardHeader: "Hector Guerra",
    cardInfo: "Chairman and President",
  },
  {
    id: 3,
    src: "/images/board-images/unisexProfilePic.jpeg",
    src2: "/images/board-images/unisexProfilePic.jpeg",
    alt: "board2.jpg",
    cardHeader: "Brandon Martin",
    cardInfo: "Treasurer",
  },
  // {
  //   id: 4,
  //   src: "/images/board-images/MDT.png",
  //   src2: "/images/board-images/MD.png",
  //   alt: "board3.jpg",
  //   cardHeader: "Michael MacDonald",
  //   cardInfo: "Project Manager",
  // },
];

export default function HomePage() {
  return (
    <div>
      {/* Hero Section Start */}
      <section className="heroSection">
        <div className="homeHeroInfoContainer">
          <h1 className="homeHeroInfoHeader">
          <TypeAnimation
            sequence={[
              'Coalition of Information Technology Businesses',
              1000,
            ]}
            speed={40}
            style={{ fontSize: '0.6em' }}
            repeat={Infinity}
          />
          </h1>
        </div>
      </section>
      <section className="aboutSection">
        <p className="aboutSectionText">
          At CoITB, we strive to promote innovation, growth, and excellence in
          Information Technology. Our mission is to empower and support various
          IT businesses, including startups in SAAP, SAAS, Artificial
          Intelligence, and comprehensive enterprise services. We are committed
          to adding value to the growth of small businesses, guiding them
          through the dynamic technological landscape.
          <Link to="/about" className="aboutSectionLink">
            Learn More
          </Link>
        </p>
        <img
          src={imageAssets.lightbulbLaptop}
          alt="lightbulbLaptop"
          className="lightbulbLaptop"
        />
      </section>
      {/* About Section End */}
      {/* Credentials Section Start */}
      {/* Credentials section requested to be removed - same as credentials page. This is to be re-worked as the same cards that allow purchase of the exams. */}
      {/* <section className="credentialsSection">
        <div className="credentialsImgContainer">
          <img
            className="credentialsImg"
            src="/images/home-images/fancy-bg-1.jpg"
            alt="fancy-bg.jpg"
          ></img>
        </div>
        <div className="credentialsSectionHeaders">
          <h5 className="credentialsSubHeader">What We Offer</h5>
          <h3 className="credentialsHeader">Credentials</h3>
        </div>
        <div className="credentialsItems">
          {credentialCardObj.map((item) => (
            <div className="credentialsItem">
              <Link
                className="credentialsAnchor"
                to={`/credentials`}
                key={item.id + item.src}
              >
                <img src={item.src} alt={item.alt}></img>
                <div className="credentialsItemContent">
                  <p className="credentialsItemHeader">{item.cardHeader}</p>
                  <p className={item.cardInfoClass}>{item.cardInfo}</p>
                  <p className="credentialsItemExtra">View Pricing</p>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </section> */}
      {/* Credentials Section End */}
      {/* Contact Section Start */}
      <section className="contactSection">
        <div className="leftSide">
          <img
            className="contactUsBg"
            src="https://api.mapbox.com/styles/v1/mapbox/streets-v12/static/pin-l+ff0000(-97.5646,35.5276)/-97.5646,35.5276,15.24,0/500x500@2x?access_token=pk.eyJ1IjoiZzAwZGt1NWgiLCJhIjoiY2x0MzZxYmJkMXYwNjJscGhjem5uam0yNiJ9.OEyKh2762F_ZwpoQ3JwoIw"
            alt="map"
          ></img>
        </div>
        <div className="rightSide">
          <div className="contactUs">
            <iframe
              src="https://forms.monday.com/forms/embed/690811b433684ac7c46519ce893fc217?r=use1"
              title="form"
              style={{
                style: "border: 0",
                boxShadow: "5px 5px 56px 0px rgba(0,0,0,.25)",
              }}
            ></iframe>
          </div>
        </div>
      </section>
      {/* Contact Section End */}
      {/* Board Section Start */}
      <section className="boardSection">
        <div className="boardImgContainer">
          <img
            className="boardImg"
            src="/images/home-images/fancy-bg-1.jpg"
            alt="fancy-bg"
          />
        </div>
        <div className="boardContentContainer">
          <div className="boardHeaderContainer">
            <p className="boardSubHeader">Our Experts</p>
            <p className="boardHeader">Meet the Board</p>
            <p className="boardDescription">
              Meet our board! Comprised of seasoned experts and visionary
              leaders, each member brings a unique set of skills and experiences
              to the table. Together, this diverse and accomplished board of
              directors guides the Coalition of Information Technology
              Businesses towards its mission of fostering the next generation of
              IT professionals and driving industry growth.
            </p>
          </div>
          <div className="boardCardsContainer">
            {boardObj.map((item) => (
              <div className="boardCard">
                <img src={item.src} alt={item.alt}></img>
                <div className="boardCardContentContainer">
                  <p className="boardCardHeader">{item.cardHeader}</p>
                  <p className="boardCardInfo">{item.cardInfo}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      {/* Board Section End */}
    </div>
  );
}
