import React from "react";
import { Typography } from "@mui/material";
import styles from "./blogPage.module.css";

const blogData = [
  {
    id: 1,
    title: "Boost Your IT Skills: How to Secure WIOA Funds for Certification",
    image: "/images/about-images/laptopChart.png",
    content: `Are you a job seeker struggling to find a new IT employment
              opportunity? Or have you decided to pursue a career in IT but do
              not have the funds to enroll in a certification program? The
              Workforce Innovation and Opportunity Act (WIOA) is the gateway to
              a world of opportunities in the IT industry.`,
    author: "COITB",
    date: "Thu Aug 01 2024",
    link: "/blog/Workforce-Innovation-Act",
  },
];

export default function BlogPage() {
  return (
    <div className={styles.blogPage}>
      <header className={styles.blogHeader}>
        <Typography variant="h1" className={styles.blogHeaderTitle}>
          Welcome to Our Blog
        </Typography>
        <img
          src="/images/blog-images/blogHeader.png"
          alt="Blog Header"
          className={styles.blogHeaderImage}
        />
      </header>
      <section className={styles.blogSection}>
        <div className={styles.box}>
          <div className={styles.blogCards}>
            <div className={styles.blogCardsRow}>
              {blogData.map((item, index) => (
                <div className={styles.cardColumn} key={index}>
                  <div
                    onClick={() => {
                      window.open(item.link, "_blank");
                    }}
                    className={styles.card}
                  >
                    <div className={styles.overlapGroup}>
                      <div
                        className={styles.overlap}
                        style={{ backgroundImage: `url(${item.image})` }}
                      ></div>
                      <Typography variant="h3" className={styles.div}>
                        {item.title}
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        className={styles.textWrapper2}
                      >
                        {item.date}
                      </Typography>
                      <Typography variant="body1" className={styles.p}>
                        {item.content}
                      </Typography>
                      <Typography variant="subtitle1" className={styles.div2}>
                        <span className={styles.span}>By </span>
                        <span className={styles.textWrapper3}>{item.author}</span>
                        {/* <span className="text-wrapper-4">{item.role || 'Role'}</span> */}
                      </Typography>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
